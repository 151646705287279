import React from 'react';
import Papa from 'papaparse';
import Cookies from 'js-cookie';
import { getDoc } from '../../API/DocList';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileCsv, faDownload } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import { docAttachment } from '../../API/DocAttachment';
import { useTranslation } from 'react-i18next';
import { attachZip } from '../../API/AttachZip';


//fonction de composant pour télécharger un fichier CSV
const DownloadCSVFile = ({ data}) => {
  const handleDownloadCSV = () => {
    const csvData = Papa.unparse(data);
    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", "instances.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const { t } = useTranslation();

  return (
    <div>
      <button
        className='btn bg-light'
        title={t("Download all instances")}
        data-toogle="tooltip"
        onClick={handleDownloadCSV}
      >
        <FontAwesomeIcon icon={faFileCsv} className='fa-copy'/>
      </button>
    </div>
  );
};


// Fonction de composant pour télécharger un document CSV spécifique.
const DownloadCSVDoc = ({ item }) => {
  const handleDownloadCSVDoc = async () => {
    try {
      const idToken = Cookies.get("Token"); // Extraction du token
      // Appel à l'API pour obtenir les données
      // const data = await getDoc(idToken, item.kInstanceId);
      // // Conversion des données en CSV
      // const csvData = Papa.unparse(data);
      // // Création du blob et de l'URL pour le téléchargement
      // const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
      const docData = await getDoc(idToken, item.kInstanceId);
      console.log("docData:", docData);
      if (!docData || docData.length === 0) {
        throw new Error("Aucune donnée de document reçue de l'API");
      }
      const instanceHead = Object.keys(item).join(';');
      const instanceResult = Object.values(item).join(';');
      const docsHead = Object.keys(docData[0]).join(';');
      const docsResult = docData.map(obj => Object.values(obj).join(';')).join('\n');

      const csvString = `${instanceHead}\n${instanceResult}\n${docsHead}\n${docsResult}`;
      console.log('csvstring:',csvString)
      // Création du blob et de l'URL pour le téléchargement
      const blob = new Blob([csvString], { type: "text/csv;charset=utf-8;" });
      const url = URL.createObjectURL(blob);
      // Création du lien et déclenchement du téléchargement
      const fileName = `${item.sInstanceName}.csv`;
      const link = document.createElement("a");
      link.setAttribute("href", url);
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Erreur lors du téléchargement du fichier CSV :", error);
    }
  };

  return (
    <div>
      <button
        className='btn '
        onClick={handleDownloadCSVDoc}
      >
        <FontAwesomeIcon icon={faFileCsv} className='fa-copy' />
      </button>
    </div>
  );
};

// Utilise la fonction `attachZip` pour gérer le téléchargement et affiche des notifications en fonction du résultat
export const handleUpload = async (selectedFile, instanceId, selectedDocumentId, modelId, fileExtension, attachZip, closeModal, setSelectedFile) => {
  if (!selectedFile) {
    // Vérifier si un fichier est sélectionné
    toast.warn("Veuillez sélectionner un fichier.", {
      autoClose: false, // Le toast ne se ferme pas automatiquement
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    return;
  }

  const idToken = Cookies.get("Token");
  try {
    // Appeler la fonction attachZip avec les paramètres nécessaires
    await attachZip(instanceId, selectedDocumentId, modelId, selectedFile, idToken, fileExtension);
    toast.success("Fichier chargé avec succès !");
    window.location.reload();
    closeModal();
  } catch (error) {
    // Gérer les erreurs lors du téléchargement du fichier
    console.log(setSelectedFile)
    console.error("Erreur lors du téléchargement du fichier :", error);
    toast.error(`Erreur lors du chargement du fichier : ${error.message}`, {
      autoClose: false, // Le toast ne se ferme pas automatiquement
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  } finally {
    // Réinitialiser le fichier sélectionné
    console.log(setSelectedFile)
    setSelectedFile(null);
  }
};

// Fonction pour télécharger des pièces jointes
export const handleDownload = async (docId, fileType, instanceId, modelId) => {
  try {
    const token = Cookies.get("Token");
    await docAttachment(token, instanceId, docId, fileType, modelId);
    console.log("Téléchargement réussi !");
  } catch (error) {
    console.error("Erreur lors du téléchargement :", error);
  }
};

export { DownloadCSVFile, DownloadCSVDoc };
