/** @format */

import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
import { getDoc } from "../../../../API/DocListPlans";
import NavBar from "../../../Navbar/Navbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import "./TabDoc.css";
import "../TabDoc/doc.css";
import {
  faSortUp,
  faSortDown,
  faCopy,
  faFileAlt,
  faUpload,
  faFileSignature,
  faArrowRotateRight,
  faArrowRightToBracket,
} from "@fortawesome/free-solid-svg-icons";
import { getModel } from "../../../../API/ModelList";
//import { getAsanaUserList } from "../../../../API/asanaUserList"; // In cae we need it that's a good start...
import "react-datepicker/dist/react-datepicker.css";
import DocStat from "../LotStat/DocStats";
import Modal from "react-modal";
import { attachZip } from "../../../../API/AttachZip";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Link } from "react-router-dom";
import config from "./config.json";
import configmodele from "../../../config.json";
import {
  handleCheckboxChange,
  ViewDocument,
  displayInputData,
} from "../../../Utils/DocsManager";
import {
  handleUpload,
  handleDownload,
} from "../../../Utils/FileDownloadManager";
import { handleCopy } from "../../../Utils/CopyDocID";
import {
  applyFilters,
  handleResetFilters,
} from "../../../Utils/FiltersManager";
import { applySort, toggleSort, sortData } from "../../../Utils/SortManager";
import { formatDateshort } from "../../../Utils/DateManager";
import { setEnvironmentColors } from "../../../Utils/EnvironnementColor";
import { useTranslation } from "react-i18next";
import styles from "../Plan01CSS.json";
import "react-toastify/dist/ReactToastify.css";
import { handleInputChange, handleUpdateSelected } from "../Utils/handlers";

const Plan01Doc = () => {
  const [modelInstanceStatuses, setModelInstanceStatuses] = useState([]);
  const [data, setData] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalIsOpen1, setModalIsOpen1] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileExtension, setFileExtension] = useState("");
  const [selectedDocuments, setSelectedDocuments] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [filter, setFilter] = useState({
    FinalCustomer: "",
    Status: "",
    isvalid: null,
  });
  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState("asc");
  const [uniqueStatuses, setUniqueStatuses] = useState([]);
  const [ModelName, setModelName] = useState([]);
  Modal.setAppElement("#root");
  const [hover, setHover] = useState(false);
  const [selectedDocumentId, setSelectedDocumentId] = useState(null);
  const [modelId, setModelId] = useState();
  const navigate = useNavigate();
  const instanceId = Cookies.get("instanceId");
  const instanceName = Cookies.get("instanceName");
  const instanceStatus = Cookies.get("instanceStatus");
  const [configData, setConfigData] = useState({});
  const itemNames = configData.itemNames || [];
  const itemVars = configData.itemVars || [];
  const [filteredData, setFilteredData] = useState([]);
  const [colorFilter, setcolorFilter] = useState("#235073");
  const [colorFilterAction, setcolorFilterAction] = useState("#ffffff");
  const [currentReviewURL, setCurrentReviewURL] = useState("");
  const [currentDocIndex, setCurrentDocIndex] = useState(null);
  const [currentDocName, setCurrentDocName] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const attachmentTypes = configmodele["IMF-DEV"]?.uploadAttachmentType || [];

  //permet de récup des datas
  useEffect(() => {
    const idToken = Cookies.get("Token");
    const loadModelData = async () => {
      const modelData = await getModel(idToken);
      setModelName(modelData[0].ModelName);
      setModelId(modelData[0].ModelId);
      const instanceStatuses = modelData[0].ModelDocumentStatus;
      setModelInstanceStatuses(instanceStatuses);
    };

    const loadAndProcessData = async () => {
      try {
        const responseData = await getDoc(idToken, instanceId);
        loadModelData();
        if (Array.isArray(responseData)) {
          setData(responseData);
          const loadedStatuses = responseData.map((item) => item.Status);
          setUniqueStatuses([...new Set(loadedStatuses)]);
        } else {
          console.error(
            "Les données chargées ne sont pas un tableau",
            responseData
          );
          setData([]);
        }
      } catch (error) {
        console.error("Erreur lors du chargement des données", error);
      }
    };

    loadAndProcessData();
  }, []);

  useEffect(() => {
    setConfigData(config);
  }, []);
  console.log(data)

  //Définie le fichier et son type pour l'appel de la fonction du button upload
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);

      // Extraction de l'extension du fichier
      const extension = file.name.split(".").pop();
      setFileExtension(extension);
    }
  };

  // Ouvre la fenêtre modale en mettant l'état `modalIsOpen` à `true`
  const openModal = () => {
    setModalIsOpen(true);
  };

  const openModal1 = (item, index) => {
    const iframeURL = `${item.reviewURL}&displayMode=iframe`;
    setCurrentReviewURL(iframeURL);
    setCurrentDocIndex(index);
    setModalIsOpen1(true);
    setCurrentDocName(item.FileName);
  };

  // Ferme la fenêtre modale en mettant l'état `modalIsOpen` à `false`
  const closeModal = () => {
    setModalIsOpen(false);
  };

  const closeModal1 = () => {
    setModalIsOpen1(false);
    setCurrentReviewURL("");
  };

  const goToNextReview = () => {
    if (currentDocIndex < filteredData.length - 1) {
      const nextIndex = currentDocIndex + 1;
      const nextItem = filteredData[nextIndex];

      setCurrentDocIndex(nextIndex);
      setCurrentReviewURL(`${nextItem.reviewURL}&displayMode=iframe`);
      setCurrentDocName(nextItem.FileName);
    }
  };

  const goToPreviousReview = () => {
    if (currentDocIndex > 0) {
      const previousIndex = currentDocIndex - 1;
      const previousItem = filteredData[previousIndex];

      setCurrentDocIndex(previousIndex);
      setCurrentReviewURL(`${previousItem.reviewURL}&displayMode=iframe`);
      setCurrentDocName(previousItem.FileName);
    }
  };

  // Appliquer les filtres sur les données sans les filtres de date
  useEffect(() => {
    const filtered = applyFilters(data, filter);
    const sorted = applySort(filtered, sortField, sortOrder);
    setFilteredData(sorted);
  }, [data, filter, sortField, sortOrder]);

  //Appliquer le tri de lorsque le champ de l'ordre de  tri change
  useEffect(() => {
    if (sortField === "CreationDate" || sortField == "LastUpdate") {
      setData((prevData) => sortData([...prevData], sortField, sortOrder));
    } else {
      setData((prevData) => applySort([...prevData], sortField, sortOrder));
    }
  }, [sortField, sortOrder]);

  //Gérer le changement de tri
  const handleSort = (field) => {
    toggleSort(field, sortField, setSortField, sortOrder, setSortOrder);
  };

  //Change les couleurs en fonction de l'environnement
  useEffect(() => {
    setEnvironmentColors([
      [setcolorFilter, "colorFilter"],
      [setcolorFilterAction, "colorFilterAction"],
    ]);
  }, []);

  function FilterChange(columnValue, inputValue) {
    setFilter({
      ...filter,
      [columnValue]: inputValue,
    });
  }

  const { t } = useTranslation();

  const isInstanceEditable = (status) => {
    const noteditableStatuses = ["completed", "production"];
    return !noteditableStatuses.includes(status);
  };

  const getUniqueValues = (columnValue) => {
    const uniqueValues = [
      ...new Set(data.map((item) => item.Input?.[columnValue]).filter(Boolean)),
    ];
    return uniqueValues;
  };

  return (
    <>
      <NavBar />
      <div className="page-container" style={{ backgroundColor: "#F7F9F9" }}>
        <div className="filAriane">
          <h6 style={{ textAlign: "left", marginTop: "10px" }}>
            <Link to="/User/">{t("AllModels")}</Link> {" > "}
            {ModelName} {" > "}
            <span
              style={{
                cursor: "pointer",
                textDecoration: hover ? "underline" : "none",
              }}
              onClick={() => navigate("/User/Lots")}
              onMouseEnter={() => setHover(true)}
              onMouseLeave={() => setHover(false)}
            >
              {instanceName}
            </span>
            <CopyToClipboard
              text={instanceId}
              onCopy={() => handleCopy(instanceId)}
            >
              <button
                className="btn btn-sm ms-2"
                data-toogle="tooltip"
                title={t("Copy instanceId")}
              >
                <FontAwesomeIcon icon={faCopy} className="fa-copy" />
              </button>
            </CopyToClipboard>
            {" > "} Documents
          </h6>
          <div>
            <DocStat lots={applySort(applyFilters(data, filter))} />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "5px",
            marginTop: "5px",
          }}
        >
          {isInstanceEditable(instanceStatus) ? (
            <button
              type="button"
              className="btn btn-primary"
              style={{ marginLeft: "2.5px", marginRight: "2.5px" }}
              onClick={() => {
                const filteredDocuments = applySort(applyFilters(data, filter));
                const documentsJson = JSON.stringify(filteredDocuments);

                localStorage.setItem("docs", documentsJson);
                localStorage.setItem("modelId", modelId);
                localStorage.setItem("instanceId", instanceId);
                localStorage.setItem("tabOpen", true);

                const newWindow = window.open("/User/DocView", "_blank");

                const intervalId = setInterval(() => {
                  if (newWindow.closed) {
                    clearInterval(intervalId);
                    localStorage.removeItem("tabOpen");
                    window.location.reload();
                  }
                }, 1000);
              }}
            >
              {t("Qualify")}
            </button>
          ) : (
            <button className="btn btn-primary" disabled>
              {t("Qualify")}
            </button>
          )}
          <button
            className="btn btn-danger"
            style={{ marginLeft: "2.5px", marginRight: "2.5px" }}
            onClick={() => navigate("/User/Lots")}
          >
            {t("Back")}
          </button>

          {isInstanceEditable(instanceStatus) ? (
            <button
              style={{ marginLeft: "2.5px", marginRight: "2.5px" }}
              className="btn btn-primary"
              onClick={() =>
                handleUpdateSelected(
                  selectedDocuments,
                  data,
                  instanceId,
                  setSelectedDocuments
                )
              }
            >
              {t("Update selected")}
            </button>
          ) : (
            <button className="btn btn-primary" disabled>
              {t("Update selected")}
            </button>
          )}
        </div>
        <div
          className="table-responsive"
          style={{
            padding: "10px",
            borderRadius: "5px",
            overflowX: "auto",
            lineHeight: "0.5",
          }}
        >
          <div style={{ overflowY: "auto" }}>
            <table
              className="table table-striped"
              style={{ tableLayout: "fixed" }}
            >
              <thead style={{ top: 0, zIndex: 2 }}>
                <tr
                  style={{
                    height: "25px",
                    verticalAlign: "middle",
                    backgroundColor: colorFilter,
                  }}
                >
                  <th
                    style={{
                      ...styles.TabDoc,
                      width: "150px",
                    }}
                  >
                    Actions
                  </th>

                  <th
                    style={{
                      ...styles.TabDoc,
                      width: "390px",
                    }}
                  >
                    {t("Document name")}
                  </th>
                  <th
                    style={{
                      width: "50px",
                      ...styles.TabDoc,
                    }}
                  >
                    Id
                  </th>
                  <th
                    style={{
                      width: "100px",
                      ...styles.TabDoc,
                    }}
                  >
                    {t("Status")}
                  </th>
                  <th style={{ width: "120px", ...styles.TabDoc }}>
                    {t("Complexity")}
                  </th>
                  <th style={{ width: "100px", ...styles.TabDoc }}>
                    {t("Validity")}
                  </th>
                  <th
                    style={{
                      width: "100px",
                      ...styles.TabDoc,
                    }}
                    onClick={() => handleSort("CreationDate")}
                  >
                    {t("Creation")}
                  </th>
                  {Array.isArray(configData.columns) &&
                  configData.columns.length > 0 ? (
                    configData.columns.map((column, index) => (
                      <th
                        key={index}
                        style={{ width: column.size, ...styles.TabDoc }}
                      >
                        {t(column.Name)}
                      </th>
                    ))
                  ) : (
                    <th>{t("Loading")}</th>
                  )}
                </tr>
              </thead>

              <tbody style={{ overflowY: "auto" }}>
                <tr
                  style={{
                    height: "25px",
                    verticalAlign: "middle",
                    top: "25px",
                    zIndex: 2,
                    backgroundColor: colorFilterAction,
                  }}
                >
                  <td style={{ ...styles.TabDoc }}>
                    <button
                      data-toggle="tooltip"
                      title={t("Reset filters")}
                      onClick={handleResetFilters}
                      className="btn bg-light text-hover-blue "
                      style={{ marginRight: "3px" }}
                    >
                      RAZ
                    </button>
                    <button
                      data-toggle="tooltip"
                      title={t("Reload page")}
                      className="btn bg-light"
                      onClick={() => window.location.reload(false)}
                    >
                      <FontAwesomeIcon
                        icon={faArrowRotateRight}
                        className="fa-copy"
                      />
                    </button>
                    <input
                      type="checkbox"
                      checked={selectedDocuments.length === data.length}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setSelectedDocuments(data.map((item) => item.DocId));
                        } else {
                          setSelectedDocuments([]);
                        }
                      }}
                    />
                  </td>

                  <td
                    style={{
                      ...styles.TabDoc,
                    }}
                  >
                    <center>
                      <input
                        type="text"
                        className="form-control filter-input"
                        value={filter.searchDoc}
                        placeholder={t("Document name or id")}
                        onChange={(e) =>
                          setFilter({ ...filter, searchDoc: e.target.value })
                        }
                        style={{
                          width: "390px",
                          fontSize: "14px",
                          height: "25px",
                        }}
                      />
                    </center>
                  </td>
                  <td
                    style={{
                      ...styles.TabDoc,
                    }}
                  ></td>

                  <td style={{ ...styles.TabDoc }}>
                    {uniqueStatuses.length > 1 ? (
                      <select
                        className="form-control"
                        onChange={(e) =>
                          setFilter({ ...filter, Status: e.target.value })
                        }
                        style={{
                          textAlign: "center",
                          fontSize: "14px",
                          height: "25px",
                          width: "100%",
                          padding: "0px",
                          verticalAlign: "middle",
                        }}
                      >
                        <option value="">{t("All")}</option>
                        {uniqueStatuses.map((status) => (
                          <option key={status} value={status}>
                            {status}
                          </option>
                        ))}
                      </select>
                    ) : (
                      <span>{uniqueStatuses[0]}</span>
                    )}
                  </td>
                  {/* trie par compléxité */}
                  <td style={{ ...styles.TabDoc }}>
                    <select
                      className="form-control"
                      onChange={(e) =>
                        setFilter({ ...filter, Complexite: e.target.value })
                      }
                      style={{
                        textAlign: "center",
                        fontSize: "14px",
                        height: "25px",
                        width: "100%",
                        padding: "0px",
                        verticalAlign: "middle",
                      }}
                    >
                      <option value="">{t("All")}</option>
                      <option value="Simple">Simple</option>
                      <option value="Moyen">{t("Medium")}</option>
                      <option value="Complexe">{t("Complexe")}</option>
                    </select>
                  </td>
                  {/* trie par Validité */}
                  <td style={{ ...styles.TabDoc }}>
                    <select
                      className="form-control"
                      onChange={(e) => {
                        setFilter({
                          ...filter,
                          isvalid:
                            e.target.value === "" ? null : e.target.value,
                        });
                      }}
                      style={{
                        textAlign: "center",
                        fontSize: "14px",
                        height: "25px",
                        width: "100%",
                        padding: "0px",
                        verticalAlign: "middle",
                      }}
                    >
                      <option value="">{t("All")}</option>
                      <option value="true">{t("True")}</option>
                      <option value="false">{t("False")}</option>
                    </select>
                  </td>

                  {/* trie par date */}
                  <td
                    style={{ ...styles.TabDoc }}
                    onClick={() => handleSort("CreationDate")}
                  >
                    {sortField === "CreationDate" && sortOrder === "asc" ? (
                      <FontAwesomeIcon
                        icon={faSortDown}
                        style={{ marginLeft: "5px", color: "white" }}
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faSortUp}
                        style={{ marginLeft: "5px", color: "white" }}
                      />
                    )}
                  </td>

                  {/* Affichage des colonnes avec configdata et fonctionnalités de tri */}
                  {Array.isArray(configData.columns) &&
                  configData.columns.length > 0 ? (
                    configData.columns.map((column, index) => (
                      <td
                        key={index}
                        style={{ width: column.size, ...styles.TabDoc }}
                      >
                        <center>
                          {column.Filter == "input" ? (
                            <input
                              type="text"
                              placeholder={column.Name}
                              //className='form-control me-2 filter-input'
                              onChange={(e) =>
                                FilterChange(column.Value, e.target.value)
                              }
                              style={{
                                width: "98%",
                                fontSize: "14px",
                                height: "25px",
                                textAlign: "center",
                              }}
                            />
                          ) : column.Filter == "select" ? (
                            <select
                              className="form-control"
                              onChange={(e) =>
                                FilterChange(column.Value, e.target.value)
                              }
                              style={{
                                textAlign: "center",
                                fontSize: "14px",
                                height: "25px",
                                width: "100%",
                                padding: "0px",
                                verticalAlign: "middle",
                              }}
                            >
                              <option value="">{t("All")}</option>
                              {getUniqueValues(column.Value).map((value) => (
                                <option key={value} value={value}>
                                  {value}
                                </option>
                              ))}
                            </select>
                          ) : null}
                        </center>
                      </td>
                    ))
                  ) : (
                    <th>{t("Loading")}</th>
                  )}
                </tr>
                {applySort(applyFilters(data, filter)).map(
                  (item, filteredIndex) => (
                    <tr
                      key={item.DocId}
                      style={{ fontSize: "12px", verticalAlign: "middle" }}
                    >
                      <td style={{whiteSpace : "normal"}}>
                        {item.Attachmeents &&
                          item.Attachmeents.length > 0 &&
                          item.Attachmeents.filter(
                            (extension) =>
                              extension !== "" && extension !== null
                          ).map((extension, index) => (
                            <button
                              title="download attachment"
                              style={styles.ActionsButton}
                              key={index}
                              className="btn"
                              onClick={() =>
                                handleDownload(
                                  item.DocId,
                                  extension,
                                  instanceId,
                                  modelId
                                )
                              }
                            >
                              {extension === "jpg" && (
                                <img
                                  src="https://cdn-icons-png.flaticon.com/256/29/29072.png"
                                  alt="JPG Icon"
                                  style={{ height: "22px", width: "22px" }}
                                />
                              )}
                              {extension === "png" && (
                                <img
                                  src="https://cdn-icons-png.flaticon.com/256/29/29264.png"
                                  alt="PNG Icon"
                                  style={{ height: "22px", width: "22px" }}
                                />
                              )}
                              {extension === "dwg" && (
                                <img
                                  src="https://cdn-icons-png.flaticon.com/256/15424/15424765.png"
                                  alt="DWG Icon"
                                  style={{ height: "22px", width: "22px" }}
                                />
                              )}
                            </button>
                          ))}
                        <button
                          title={t("upload attachement")}
                          style={styles.ActionsButton}
                          className="btn"
                          onClick={() => {
                            openModal();
                            setSelectedDocumentId(item.DocId);
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faUpload}
                            style={{ marginRight: "5px" }}
                            className="fa-copy"
                          />
                        </button>
                        <button
                          title={t("view document")}
                          style={styles.ActionsButton}
                          className="btn"
                          onClick={() => ViewDocument(item.DocId, item.ModelId)}
                        >
                          <FontAwesomeIcon
                            className="fa-copy"
                            icon={faFileAlt}
                            style={{ marginRight: "5px" }}
                          />
                        </button>
                        {item.Input && Object.keys(item.Input).length > 0 && (
                          <button
                            title="Voir Input"
                            style={styles.ActionsButton}
                            className="btn"
                            onClick={() => displayInputData(item.Input)}
                          >
                            <FontAwesomeIcon
                              icon={faArrowRightToBracket}
                              style={{ marginRight: "5px" }}
                              className="fa-copy"
                            />
                          </button>
                        )}
                        {item.reviewURL !== undefined &&
                          item.reviewURL !== null &&
                          item.Status !== "completed" && (
                            <button
                              title="VideoCodage"
                              style={styles.ActionsButton}
                              data-toogle="tooltip"
                              className="btn"
                              onClick={() => openModal1(item, filteredIndex)}
                            >
                              <FontAwesomeIcon
                                className="fa-copy"
                                icon={faFileSignature}
                                style={{ marginRight: "5px" }}
                              />
                            </button>
                          )}
                        <input
                          type="checkbox"
                          checked={selectedDocuments.includes(item.DocId)}
                          onChange={(e) =>
                            handleCheckboxChange(
                              e,
                              item.DocId,
                              selectedDocuments,
                              setSelectedDocuments
                            )
                          } // Gérer les changements de checkbox
                        />
                      </td>

                      <td title={item.DocId}>{item.FileName}</td>

                      <td title={t("Copy")}>
                        <CopyToClipboard
                          text={item.DocId}
                          onCopy={() => handleCopy(item.DocId)}
                        >
                          <button className="btn">
                            <FontAwesomeIcon
                              className="fa-copy"
                              icon={faCopy}
                              style={{ cursor: "pointer" }}
                            />
                          </button>
                        </CopyToClipboard>
                      </td>

                      <td>{item.Status}</td>

                      <td>
                        {item.Status != "invalid" && (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            {["Simple", "Moyen", "Complexe"].map(
                              (Complexite, index) => {
                                const colors = ["green", "orange", "red"];
                                const isSelected =
                                  item.Complexite === Complexite;
                                const baseColor = colors[index];
                                const backgroundColor = isSelected
                                  ? baseColor
                                  : "#f0f0f0";
                                const color = isSelected ? "white" : baseColor;

                                return (
                                  <button
                                    key={Complexite}
                                    style={{
                                      backgroundColor,
                                      color,
                                      border: `1px solid ${baseColor}`,
                                      padding: "5px 10px",
                                      margin: "0 5px",
                                      cursor: "pointer",
                                      borderRadius: "5px",
                                    }}
                                    onClick={() =>
                                      handleInputChange(
                                        { target: { value: Complexite } },
                                        item.DocId,
                                        "Complexite",
                                        data,
                                        setData,
                                        setSelectedDocuments
                                      )
                                    }
                                  >
                                    {Complexite[0]}
                                  </button>
                                );
                              }
                            )}
                          </div>
                        )}
                        {item.Status == "invalid" && (
                          <div> {item.Complexite} </div>
                        )}
                      </td>

                      <td
                        style={{
                          align: "center",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {item.Status != "invalid" && (
                          <select
                            className="form-control"
                            value={
                              item.isvalid !== undefined
                                ? item.isvalid
                                  ? "true"
                                  : "false"
                                : ""
                            }
                            onChange={(e) =>
                              handleInputChange(
                                e,
                                item.DocId,
                                "isvalid",
                                data,
                                setData,
                                setSelectedDocuments
                              )
                            }
                            style={{
                              width: "100%",
                              textAlign: "center",
                              height: "25px",
                              fontSize: "12px",
                              padding: "1px",
                              color: item.isvalid ? "#006400" : "#8B0000",
                              backgroundColor: item.isvalid
                                ? "#90EE90"
                                : "#FFA07A",
                            }}
                          >
                            <option value="">Valide</option>
                            <option value="true">Vrai</option>
                            <option value="false">Faux</option>
                          </select>
                        )}
                        {item.Status == "invalid" && (
                          <div> {item.isvalid ? "Vrai" : "Faux"} </div>
                        )}
                      </td>

                      <td>{formatDateshort(item.CreationDate)}</td>
                      {itemVars.map(
                        (itemVar) =>
                          configData.displayFields?.[itemVar] && (
                            <td>
                              {""}
                              {item[itemVar]}
                            </td>
                          )
                      )}

                      {Array.isArray(configData.columns) &&
                      configData.columns.length > 0 ? (
                        configData.columns.map((column, index) => {
                          return (
                            <td
                              key={index}
                              style={{
                                textAlign: "center",
                                overflow: "visible",
                                textOverflow: "unset",
                                whiteSpace: "normal",
                                minWidth: "150px",
                                maxWidth: "500px",
                                wordWrap: "break-word",
                                lineHeight: "1",
                              }}
                            >
                              {item.Input?.[column.Value]}
                            </td>
                          );
                        })
                      ) : (
                        <td>{t("Loading")}</td>
                      )}
                    </tr>
                  )
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {modalIsOpen && (
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={{
            content: {
              backgroundColor: "#f7f7f7",
              padding: "20px",
              borderRadius: "8px",
              maxWidth: "500px",
              maxHeight: "280px",
              margin: "auto",
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
            },
            overlay: {
              backgroundColor: "rgba(0, 0, 0, 0.5)",
            },
          }}
        >
          <div>
            <h2 style={{ marginBottom: "15px" }}>
              {t("Upload attachement title")}
            </h2>
            <input
              type="file"
              onChange={(e) =>
                handleFileChange(e, setSelectedFile, setFileExtension)
              }
              style={{
                display: "block",
                margin: "10px 0",
                padding: "10px",
                fontSize: "14px",
              }}
            />

            <p>
              {t("The authorized extensions are :")}{" "}
              {attachmentTypes.join(", ")}
            </p>
            <p style={{ color: "red" }}>{errorMessage}</p>

            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <button
                onClick={() => {
                  if (!selectedFile) {
                    setErrorMessage(t("No files added."));
                    return;
                  }
                  if (!attachmentTypes.includes(fileExtension)) {
                    setErrorMessage(
                      t("Unauthorized extension :") + ` ${fileExtension}`
                    );
                    return;
                  }
                  setErrorMessage("");
                  handleUpload(
                    selectedFile,
                    instanceId,
                    selectedDocumentId,
                    modelId,
                    fileExtension,
                    attachZip,
                    closeModal,
                    setSelectedFile
                  );
                }}
                style={{
                  padding: "10px 15px",
                  backgroundColor: "#007BFF",
                  color: "white",
                  border: "none",
                  borderRadius: "5px",
                  cursor: "pointer",
                }}
              >
                {t("Upload")}
              </button>
              <button
                onClick={() => {
                  closeModal();
                  setErrorMessage("");
                }}
                style={{
                  padding: "10px 15px",
                  backgroundColor: "#ccc",
                  color: "#000",
                  border: "none",
                  borderRadius: "5px",
                  cursor: "pointer",
                }}
              >
                {t("Close")}
              </button>
            </div>
          </div>
        </Modal>
      )}
      {}
      <Modal
        isOpen={modalIsOpen1}
        onRequestClose={closeModal1}
        contentLabel="abby url"
      >
        <h3>{currentDocName}</h3>
        <iframe
          src={currentReviewURL}
          style={{ width: "100%", height: "100%" }}
        />
        <button onClick={closeModal1}>Close</button>
        <button onClick={goToPreviousReview} disabled={currentDocIndex <= 0}>
          Previous Document
        </button>
        <button
          onClick={goToNextReview}
          disabled={currentDocIndex >= applyFilters(data, filter).length - 1}
        >
          Next document
        </button>
      </Modal>
    </>
  );
};

export default Plan01Doc;
