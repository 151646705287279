import React from "react";

//Formate une chaine de date personnalisée pour l'affichage
export function formatCustomDate(dateString){

    const date = parseCustomDate(dateString);
    const options = { year: "numeric", month: "2-digit", day: "2-digit" };
    return (
      date.toLocaleDateString("fr-FR", options) +
      " " +
      date.toLocaleTimeString("fr-FR")
    );

};

//Analyse une chaine de date personnalisée et retourne un objet Date
export function parseCustomDate(dateString) {
  if (!dateString || typeof dateString !== "string" || dateString.length !== 15) {
    console.error("Invalid date string:", dateString);
    return new Date(); // Retourne une date par défaut en cas d'erreur
  }

  const year = parseInt(dateString.slice(0, 4), 10);
  const month = parseInt(dateString.slice(4, 6), 10) - 1; // Les mois commencent à 0 en JavaScript
  const day = parseInt(dateString.slice(6, 8), 10);
  const hours = parseInt(dateString.slice(9, 11), 10);
  const minutes = parseInt(dateString.slice(11, 13), 10);
  const seconds = parseInt(dateString.slice(13, 15), 10);

  return new Date(year, month, day, hours, minutes, seconds);
}

//Analyse une chaine de date pour une remise et retroune une date formatée
export function parseRemiseDate (dateStr) {   
  if (dateStr && typeof dateStr == "string" && dateStr.length > 10) {
    const year = String(parseInt(dateStr.slice(0, 4), 10));
    const month = String(parseInt(dateStr.slice(4, 6), 10) ).padStart(2,'0');  
    const day = String(parseInt(dateStr.slice(6, 8), 10)).padStart(2,'0');
    return year+'-'+month+'-'+day;
  } else {
    return "";
  }
};

//Formate un Objet Date pour le stockage
export function formatDateForStorage(date){

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");

    return `${year}${month}${day}-${hours}${minutes}${seconds}`;

};

//Formate une chaine de date en fonction des otions locales pour l'affichage en YY-MM-DD : HH-MM-SS
export function formatDateLong(dateString) {
  const options = { year: "numeric", month: "2-digit", day: "2-digit" };
  const date = new Date(dateString);
  return date.toLocaleDateString("fr-FR", options);
};

//Formate une chaine de date en fonction des option locales pour l'affichage en DD--MM--YY
export function formatDateshort (dateStr) {
  if (!dateStr || dateStr.length < 8) return "";
  const year = dateStr.substring(0, 4);
  const month = dateStr.substring(4, 6);
  const day = dateStr.substring(6, 8);
  return `${day}-${month}-${year}`;
};

