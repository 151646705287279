/** @format */

import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import Cookies from "js-cookie";
import { useEffect } from "react";
import Plan01Lots from "../Component/Dashboard/PLAN01/TabLots/TabLots";
import Plan01Docs from "../Component/Dashboard/PLAN01/TabDoc/TabDoc";
import Ocr01Lots from "../Component/Dashboard/OCR01/TabLots/TabLots";
import Ocr01Docs from "../Component/Dashboard/OCR01/TabDoc/TabDoc";
import Import from "../Component/Dashboard/Import/Import";
import DefaultLots from "../Component/Dashboard/Default/TabLots/TabLots";
import DefaultDocs from "../Component/Dashboard/Default/TabDoc/TabDoc";
import DocViewer from "../Component/Dashboard/PLAN01/TabDoc/DocViewer";
import ModelsVideoCodage from "../Component/Dashboard/Default/VideoCodage/ModelsVideoCodage";
export default function RouterUser() {
  const token = Cookies.get("Token");
  const role = Cookies.get("Role");

  const checkTokenExpiration = () => {
    const token = Cookies.get("Token");

    if (token) {
      const tokenExpirationTime = new Date(
        JSON.parse(atob(token.split(".")[1])).exp * 1000
      );
      const currentTime = new Date();

      console.log("Token expiration time:", tokenExpirationTime);
      console.log("Current time:", currentTime);

      if (currentTime > tokenExpirationTime) {
        console.log("Token expired. Logging out...");
        Cookies.remove("Token");
        localStorage.clear();
        window.location.href = "/"; // Rediriger vers la page de déconnexion
      }
    }
  };

  useEffect(() => {
    // Vérifier périodiquement si le token a expiré (par exemple, toutes les minutes)
    const interval = setInterval(checkTokenExpiration, 60000); // Vérifier toutes les 60 secondes (60000 ms)

    return () => {
      clearInterval(interval); // Nettoyer l'intervalle à la suppression du composant
    };
  }, []);

  if (!token || role !== "user") {
    return <Navigate to="/" replace />;
  }

  return (
    <>
      <Routes>
        <Route
          path="/*"
          element={
            <>
              <DefaultLots />{" "}
            </>
          }
        />
        {
          // <Route path='/Model' element={<ModelSelector />} />
        }{" "}
        <Route path="/Lots" element={<DefaultLots />} />
        <Route path="/Docs" element={<DefaultDocs />} />
        <Route path="/Import" element={<Import />} />
        <Route path="/plan01Lots" element={<Plan01Lots />} />
        <Route path="/plan01Docs" element={<Plan01Docs />} />
        <Route path="/ocr01Lots" element={<Ocr01Lots />} />
        <Route path="/ocr01Docs" element={<Ocr01Docs />} />
        <Route path="/DocView" element={<DocViewer />} />
        <Route path="/ModelsVideoCodage" element={<ModelsVideoCodage />} />
      </Routes>
    </>
  );
}
